





















import Vue from "vue";
import Component from "vue-class-component";

@Component({
  components: {},
})
export default class NotFound extends Vue {
  mounted() {
    this.$self_render.navbarEnable = false;
  }
  goToBack() {
    this.$self_render.navbarEnable = true;
    this.$router.push({ path: "/" });
  }
}
