




































































































































































































































































import { VeinShard } from "@/models";
import Vue from "vue";
import { Component } from "vue-property-decorator";
import NotFound from "./../components/NotFound.vue";
import vsDivider from "./../components/vsDivider.vue";
import { Author, PackageReference, PackageVersions } from "@/models/VeinShard";
import axios from "axios";
import VerifiedBadge from "@/components/VerifiedBadge.vue";

@Component({
  components: {
    "not-found": NotFound,
    "vs-divider": vsDivider,
    "verified-badge": VerifiedBadge,
  },
})
export default class PackageView extends Vue {
  metaInfo() {
    return {
      title: `${this.packageName} ${this.packageVersion}`,
      meta: [
        {
          name: "description",
          content: this.currentPackage?.description ?? "",
        },
      ],
    };
  }
  activeTab = "readme";
  constructor() {
    super();
    (window as any)["PackageView"] = this;
  }
  readmeMarkdown: string = "<h2>No readme detected</h2>";

  get packageName() {
    return this.$route.params["id"];
  }
  get packageVersion() {
    return this.$route.params["version"];
  }
  set packageVersion(v: string) {
    this.$route.params["version"] = v;
  }

  isLoading: boolean = true;
  currentPackage?: VeinShard;
  isNotFound: boolean = false;
  downloadPackageActive: boolean = false;

  getSocials(): Record<string, string> | null {
    if (!this.currentPackage?.urls) return null;
    if (this.currentPackage?.urls.other)
      return {
        ...this.currentPackage?.urls,
        ...JSON.parse(this.currentPackage?.urls.other),
      };
    else return { ...this.currentPackage?.urls } as any;
  }

  getDependecies(): PackageReference[] | null {
    if (!this.currentPackage?.dependencies) return null;
    if (this.currentPackage?.dependencies.length == 0) return null;
    return this.currentPackage.dependencies;
  }

  goToUrl(s: string) {
    window.open(s);
  }

  getAuthors(): Author[] {
    return this.currentPackage?.authors ?? [];
  }


  templateDependencyLink(dep: PackageReference) {
    return `/package/${dep.name}/${dep.version}`;
  }

  templateVersionLink(ver: string) {
    return `/package/${this.currentPackage?.name}/${ver}`;
  }

  async created() {
    this.init();
  }

  async init() {
    if (!this.packageVersion || !this.packageName) {
      this.isNotFound = true;
      return;
    }
    const loading = this.$vs.loading({
      target: this.$refs.content,
      scale: "0.6",
      color: "#fff",
      type: 'rectangle',
      text: 'Fetching package...'
    });

    let result = await this.$axios.$get(
      `@/package/${this.packageName}/${this.packageVersion}`
    );

    if (result.status == 404) {
      this.isNotFound = true;
      loading.close();
      return;
    }

    console.warn(`[package]`, result);

    loading.close();
    this.currentPackage = result.data;
    this.packageVersion = this.currentPackage!.version;
    this.isLoading = false;
    if (this.currentPackage?.hasEmbbededReadme)
      this.$axios
        .$get(`@/packages/${this.packageName}/${this.packageVersion}/readme`)
        .then((x) => {
          this.readmeMarkdown = x.data;
        });

    await this.checkoutVersions();
  }


  versions: PackageVersions = {
    versions: [],
    downloads: [],
    dates: []
  };

  async checkoutVersions() {
    let result = await this.$axios.$get(
      `@/packages/${this.packageName}/version.json`
    );
    if (result.status == 404) {
      return;
    }
    this.versions = result.data;
  }

  downloadPackage() {
    if (this.downloadPackageActive) return;
    this.downloadPackageActive = true;
    setTimeout(() => {
      this.downloadPackageActive = false;
      this.currentPackage!.downloads++;
    }, 4500);
    this.downloadItem({
      url: `@/packages/${this.packageName}/${this.packageVersion}`,
      label: `${this.packageName}-${this.packageVersion}.shard`,
    });
  }

  async downloadItem({ url, label }) {
    const response = await axios.get(url, { responseType: "blob" });
    const blob = new Blob([response.data], { type: "binary/octet-stream" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = label;
    link.click();
    URL.revokeObjectURL(link.href);
  }

  getTimeAgo(p: VeinShard | string) {
    if (typeof p === "string")
      return this.$timeAgo.format(new Date(Date.parse(p)));
    let time = new Date(Date.parse(p.published));
    return this.$timeAgo.format(time);
  }

  copyCommand(p: VeinShard | undefined) {
    if (!p) return;

    if (p.isWorkload) {
      navigator.clipboard.writeText(`rune workload install ${p.name} --version ${p.version}`);
      console.log(
        `Text '${`rune workload install ${p.name} --version ${p.version}`}' copied to clipboard`
      );
    } else {
      navigator.clipboard.writeText(`rune add ${p.name} --version ${p.version}`);
      console.log(
        `Text '${`rune add ${p.name} --version ${p.version}`}' copied to clipboard`
      );
    }

    this.$vs.notification({
      sticky: true,
      icon: `<i class='bx bx-select-multiple'></i>`,
      color: "warn",
      position: "bottom-left",
      duration: null,
      progress: "auto",
      title: "Command has been copied!",
    });
  }
}
