import { render, staticRenderFns } from "./PackageView.vue?vue&type=template&id=77aeb2f9&scoped=true&"
import script from "./PackageView.vue?vue&type=script&lang=ts&"
export * from "./PackageView.vue?vue&type=script&lang=ts&"
import style0 from "./PackageView.vue?vue&type=style&index=0&lang=css&"
import style1 from "./PackageView.vue?vue&type=style&index=1&id=77aeb2f9&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77aeb2f9",
  null
  
)

export default component.exports